<template>
	<section class="news-overview">
		<template v-if="$root.rwd < 2">
			<lottie :options="{ path: '/lottie/Dandelion/data.json', autoplay: true, loop: true }" />
		</template>
		<div class="max-width-1000">
			<quote-title label="焦點消息" />
			<br>
			<div class="row-grid" gutter-x="30" gutter-y="20">
				<template v-for="(item, i) in _newsList">
					<router-link :to="`/news/${item.id}`" class="col rwd-l-3 rwd-m-4 rwd-s-6" :key="item.id || i">
						<thumb :src="item.thumb" />
						<br>
						<pre v-if="item.title" style="text-align:center;">{{item.title}}</pre>
						<br>
					</router-link>
				</template>
			</div>
			<br>
		</div>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	components: {
		Thumb: () => import("@/components/thumb")
	},
	computed: {
		...mapState("news", ["_newsList"])
	},
	methods: {
		...mapActions("news", ["_getList"]),
	},
	async created() {
		if (!this._newsList.length) {
			await this._getList();
		}
	}
}
</script>

<style lang="scss" scoped>
.news-overview {
	padding: 50px 5%;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../../../assets/background/brush3.png"),
		url("../../../assets/background/dot5.png");
	background-position: center bottom, center 80%;

	.lottie {
		position: absolute;
		width: 70%;
		bottom: 0;
		right: 20px;
	}
}
</style>